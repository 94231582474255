<template>
  <v-container fluid>
    <base-form
      v-if="editItem"
      :editStatus="true"
      :editItem="editItem"
      @update="handleSubmit"
      @cancel="handleCancel"
    />
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    props: {
      id: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        editItem: null
      }
    },

    computed: {
      ...mapGetters({
        currentContract: 'contract/currentContract'
      })
    },

    created () {
      if (this.id) {
        this.contractShow( this.id ).then( () => {
          this.editItem = this.currentContract
        } )
      }
    },

    methods: {
      ...mapActions({
        contractShow: 'contract/show',
        contractUpdate: 'contract/update'
      }),
      handleSubmit (data) {
        this.contractUpdate(data).then(() => {
          this.$router.push({name: 'contractList'})
        })
      },
      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
